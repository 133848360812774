import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useMsal} from "@azure/msal-react";
import {AccountInfo, InteractionRequiredAuthError} from "@azure/msal-browser";
import {getRedirectUri, loginRequest} from "../authConfig";
import {useCookies} from "react-cookie";
import {useErrorReporter} from "../support/ErrorViewer";
import Semaphore from "semaphore-async-await";

interface AuthContextType {
    activeOrganization: string | null;
    setActiveOrganization: (organization: string | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    const [activeOrganization, setActiveOrganization] = useState<string | null>(null);


    return (
        <AuthContext.Provider value={{
            activeOrganization,
            setActiveOrganization
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};

export class NeedsReLoginError extends Error {
    constructor() {
        super("Token expired, re-login is required");
        this.name = "NeedsReLoginError";
    }

    toString() {
        return this.message;
    }
}

const singletonLock = new Semaphore(1);
export const useAuth = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["session_id"]);
    const {instance, accounts} = useMsal();
    const {activeOrganization, setActiveOrganization} = useAuthContext();
    const reportError = useErrorReporter();



    function dependOn(ignored: AccountInfo[]) {
        // add noop of an implicit dependency, like accounts. Do not remove!
    }

    const getToken = useCallback(async () => {
        dependOn(accounts);
        const account = instance.getActiveAccount();
        if (account == null) {
            throw new Error("No accounts found");
        }
        try {
            await singletonLock.acquire();
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            });
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                throw new NeedsReLoginError();
            }
            throw error;
        } finally {
            singletonLock.release();
        }
    }, [instance, accounts]);

    const getHeaders = useCallback(async (): Promise<Record<string, string>> => {
        const token = await getToken();
        const headers: Record<string, string> = {};
        headers["Authorization"] = `Bearer ${token}`
        if (activeOrganization != null) {
            headers["ActiveOrganization"] = activeOrganization;
        }
        return headers;
    }, [getToken, activeOrganization]);

    const isLoggedIn = useCallback(() => {
        dependOn(accounts);
        return instance.getActiveAccount() != null;
    }, [instance, accounts]);

    useEffect(() => {
        if (!isLoggedIn()) {
            if (cookies["session_id"] != null) {
                removeCookie("session_id")
            }
        }
    }, [isLoggedIn, cookies, removeCookie]);


    const getActiveAccount = useCallback(() => {
        return instance.getActiveAccount();
    }, [instance]);

    const setOrganization = useCallback((organization: string) => {
        setActiveOrganization(organization);
    }, [setActiveOrganization]);

    const loginPopup = useCallback(async () => {
        try {
            const res = await instance.loginPopup({
                ...loginRequest,
                redirectUri: getRedirectUri()
            })
            instance.setActiveAccount(res.account)
        } catch (error) {
            reportError(`${error}`);
        }
    }, [instance, reportError]);

    const logout = useCallback(async () => {
        if (instance.getActiveAccount() != null) {
            await instance.logoutPopup({
                account: instance.getActiveAccount(),
            });
            instance.setActiveAccount(null);
        }
        removeCookie("session_id")
    }, [instance, removeCookie]);

    const getUserId = useCallback(() => {
        dependOn(accounts);
        return instance.getActiveAccount()?.localAccountId;
    }, [instance, accounts]);

    const setSessionIdCookie = useCallback(async () => {
        const accessToken = await getToken();
        if (cookies["session_id"] != accessToken) {
            setCookie("session_id", accessToken, {path: "/"})
        }
    }, [getToken, setCookie, cookies]);


    return useMemo(() => {
        return {
            setSessionIdCookie,
            getToken,
            getHeaders,
            setOrganization,
            isLoggedIn,
            getActiveAccount,
            loginPopup,
            logout,
            getUserId
        }
    }, [setSessionIdCookie, getToken, getHeaders, setOrganization, isLoggedIn, getActiveAccount, loginPopup, logout, getUserId]);
}