import ReactMarkdown from "react-markdown";
import {Options} from "react-markdown/lib";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {atomDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {resolve_relative} from "./Resolve_relative";
import rehypeRaw from "rehype-raw";

interface ProductVersionChoice {
    type: "choice_request"
    queries: {
        subject: string
        choices: string[]
    }[]

}

type ExtendedOptions = Options & {
    onVersionChange: null | ((product: string, version: string) => void)
}

export function TailoredMarkdown(options: ExtendedOptions) {
    const [selected, setSelected] = useState({} as { [id: string]: string })
    const {t} = useTranslation()
    const navigate = useNavigate()

    function to_version_choice(languageMatch: RegExpExecArray | null, children: any): (ProductVersionChoice | null) {
        const isJsonWithChildren = languageMatch != null && languageMatch[1] === 'json' && children != undefined;
        if (!isJsonWithChildren) {
            return null
        }
        try {
            const json_doc = JSON.parse(String(children)) as ProductVersionChoice
            if (json_doc.type != "choice_request" ||
                json_doc.queries === undefined ||
                json_doc.queries.length == 0) {
                return null
            }
            const allQueryValid = json_doc.queries.filter((query) => {
                return query.choices != undefined && query.choices.length > 0
            }).length > 0
            if (!allQueryValid) {
                return null
            }
            return json_doc
        } catch (e) {
            return null
        }
    }

    const handleVersionChange = useCallback(async (product: string, version: string) => {
        setSelected((old) => {
            const newVersions = {...old}
            if (version == "" && product in newVersions)
                delete newVersions[product]
            if (version != "")
                newVersions[product] = version
            return newVersions
        })
        if (options.onVersionChange != null) {
            options.onVersionChange(product, version)
        }
    }, [options, setSelected])

    return <ReactMarkdown className="w-full"
        rehypePlugins={[rehypeRaw]}
        {...options}
        components={{
            code(props) {
                const {children, className, node, ...rest} = props
                const languageMatch = /language-(\w+)/.exec(className ?? '')
                const json_doc = to_version_choice(languageMatch, children)
                if (json_doc != null) {
                    if (options.onVersionChange == null) {
                        return <>
                            {json_doc.queries.map((query) => {
                                return <div key={query.subject}>
                                <span className={"px-4"}>
                                    {query.subject}:
                                </span>
                                    <ol className={`rounded-lg px-4 text-s list-disc list-inside`}>
                                        {query.choices.map((choice: string) => {
                                            return <li key={choice}>{choice}</li>
                                        })}
                                    </ol>
                                </div>
                            })
                            }
                        </>
                    } else {
                        return <table className={`space-y-4`}>
                            <tbody>
                            {json_doc.queries.map((query, i) => {
                                return <tr key={`row_${i}`}>
                                    <td className={"px-4"}>
                                        {query.subject}
                                    </td>
                                    <td className="py-1">
                                        <select id={`version_selection_${query.subject}`}
                                                className={`bg-gray-dark-enov rounded-lg px-4 text-sm`}
                                                defaultValue={selected[query.subject]}
                                                onChange={(e) => handleVersionChange(query.subject, e.target.value)}>
                                            <option value={""}></option>
                                            {query.choices.map((choice: string) => {
                                                return <option className="test-sm" key={choice} value={choice}>{choice}</option>
                                            })}
                                        </select>
                                        <span
                                            className={"text-sm px-2"}>{selected[query.subject] === undefined ? t('Select version') : t('Marked for sending')}</span>
                                    </td>
                                </tr>
                            })
                            }
                            </tbody>
                        </table>
                    }
                }
                return languageMatch ? (
                    <SyntaxHighlighter
                        language={languageMatch[1]}
                        style={atomDark}
                        wrapLongLines={true}
                    >
                        {String(children)}
                    </SyntaxHighlighter>
                ) : (
                    <code {...rest} className={className}>
                        {children}
                    </code>
                )
            },
            ol(props) {
                return <ol type={"1"} className="space-y-2 pl-8 list-decimal">{props.children}</ol>
            },
            ul(props) {
                return <ul className="space-y-2 pl-8">{props.children}</ul>
            },
            a(props) {
                const href = props.href
                const propsWithoutHref = {...props}
                delete propsWithoutHref.href
                if (href != undefined && (href.startsWith("/cases") || href.startsWith("/docs"))) {
                    return (
                        <Link to={href} className="text-blue-400 underline">
                            {props.children}
                        </Link>
                    )
                }
                return <a {...props}>{props.children}</a>
            },
            img(props) {
                const {src, alt} = props;
                if (src == null || typeof src != 'string' || src.startsWith("http://") || src.startsWith("https://")) {
                    return <img {...props} />;
                }
                const abs_src = `/internal${src.startsWith('/') ? '' : '/'}${src}`;
                const rest_props = {...props};
                delete rest_props.src;
                return <img className="py-6" src={abs_src} alt={`${alt ?? ""}`} {...rest_props} />;
            }
        }}
    />
}