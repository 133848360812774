import {SupportFrame} from "./SupportFrame";
import {ErrorStateDomain} from "./ErrorViewer";
import {CookieConsent} from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import React from "react";
import {CookiesProvider} from "react-cookie";
import {AuthProvider} from "../auth/AuthHook";
import {BrowserRouter, Route, RouterProvider, Routes} from "react-router-dom";
import {CaseStateMapContext, CaseStateMapProvider, SendingLimitsProvider, SupportCaseState} from "./CaseHook";

interface SupportAppProps {
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SupportApp(props: SupportAppProps) {
    const {t} = useTranslation()

    return (
        <div className="dark:bg-slate-800 dark:text-gray-300 h-screen font-inter">
            <ErrorStateDomain>
                <AuthProvider>
                    <SendingLimitsProvider>
                        <CaseStateMapProvider>
                            <CookiesProvider defaultSetOptions={{path: '/'}}>
                                <div className="flex flex-col h-screen">
                                    <BrowserRouter>
                                        <Routes>
                                            <Route path={"/"} element={<SupportFrame/>}/>
                                            <Route path={"/cases/:caseId"} element={<SupportFrame show="cases"/>}/>
                                            <Route
                                                path={"/docs/*"}
                                                element={<SupportFrame show="docs"/>}
                                            />
                                            <Route
                                                path={"*"}
                                                element={<div>NOT FOUND XX</div>}
                                            />
                                        </Routes>
                                        <CookieConsent
                                            location="bottom"
                                            buttonText={t("Accept cookies")}
                                            declineButtonText={t("Decline cookies")}
                                            enableDeclineButton={true}
                                            flipButtons={true}
                                            style={{background: "#2B373B"}}
                                            buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                                            debug={false}
                                        >
                                            {t("Cookie consent")}
                                        </CookieConsent>
                                    </BrowserRouter>
                                </div>
                            </CookiesProvider>
                        </CaseStateMapProvider>
                    </SendingLimitsProvider>
                </AuthProvider>
            </ErrorStateDomain>
        </div>
    )
}