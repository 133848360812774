import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {BackendMessageResponse, SupportCase} from "./model";
import {useParams} from "react-router-dom";
import {CaseMessage, CaseMessageRole, CaseMessageType} from "./Conversation";
import {toRole} from "./utils";
import {useAuth} from "../auth/AuthHook";
import {useErrorReporter} from "./ErrorViewer";

const emptyCase = () => {
    return {
        case_id: null,
        title: "",
        severity: "",
        created_by: "",
        status: "",
        messages: []
    } as SupportCase
}

export interface SupportCaseState {
    supportCase?: SupportCase
    isLoading: boolean
    isSyncing: boolean
    error?: string
}

interface CaseStateMapContextType {
    caseStateMap: Map<string, SupportCaseState>
    setCaseStateMap: (caseStateMapSetter: (prevState: Map<string, SupportCaseState>) => Map<string, SupportCaseState>) => void
}

interface SendingLimitsType {
    limit: number,
    period: number,
    remaining: number,
    cooldown: number,
    lastUpdated?: number
}

interface SendingLimitsContextType {
    limits: SendingLimitsType
    setLimits: (limitsSetter: (prevState: SendingLimitsType) => SendingLimitsType) => void
}

export const CaseStateMapContext = createContext<CaseStateMapContextType | undefined>(undefined)
export const SendingLimitsContext = createContext<SendingLimitsContextType>({
    limits: {
        limit: 0,
        period: 0,
        remaining: -1,
        cooldown: 0,
        lastUpdated: undefined
    },
    setLimits: () => {
        throw new Error("setLimits must be used within a SendingLimitsProvider")
    }
})

const useCaseStateContext = () => {
    const context = useContext(CaseStateMapContext)
    if (context === undefined) {
        throw new Error('useCaseStateContext must be used within a CaseStateMapProvider')
    }
    return context
}

export const CaseStateMapProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    const [caseStateMap, setCaseStateMap] = useState<Map<string, SupportCaseState>>(new Map())
    return (
        <CaseStateMapContext.Provider value={{
            caseStateMap,
            setCaseStateMap
        }}>
            {children}
        </CaseStateMapContext.Provider>
    )
}

export const SendingLimitsProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    const [limits, setLimits] = useState<SendingLimitsType>({
        limit: 0,
        period: 0,
        remaining: -1,
        cooldown: 0,
        lastUpdated: undefined
    })
    return (
        <SendingLimitsContext.Provider value={{
            limits,
            setLimits
        }}>
            {children}
        </SendingLimitsContext.Provider>
    )
}

export const useSupportCase = () => {
    const {caseId} = useParams()
    const {getHeaders, isLoggedIn} = useAuth()
    const reporter = useErrorReporter()
    const {caseStateMap, setCaseStateMap} = useCaseStateContext()
    const [lastCaseIdLoaded, setLastCaseIdLoaded] = useState<string | undefined>(undefined)
    const {limits, setLimits} = useContext(SendingLimitsContext)


    const updateSupportCase = useCallback((updateFn: (supportCase: SupportCaseState) => SupportCaseState) => {
        setCaseStateMap(prevState => {
            if (caseId === null || caseId === undefined) {
                return prevState
            }
            const prevCaseState = prevState.get(caseId);
            const newCaseState = updateFn(prevCaseState || {
                supportCase: emptyCase(),
                isLoading: false,
                isSyncing: false
            })
            const res = new Map(prevState);
            res.set(caseId, newCaseState)
            return res
        })
    }, [caseId, setCaseStateMap])

    const fetchData = useCallback(async (url: string, method: string, body?: any) => {
        try {
            const resp = await fetch(url, {
                method: method,
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                    credentials: "same-origin"
                },
                body: body == undefined ? undefined : JSON.stringify(body)
            })
            if (!resp.ok) {
                reporter(`Error fetching data: ${await resp.text()}`)
                if (resp.status === 429) {
                    setLimits((prevState) => {
                        return {
                            limit: Number(resp.headers.get("x-ratelimit-limit-requests")!) ?? prevState.limit,
                            period: Number(resp.headers.get("x-ratelimit-period-requests")!) ?? prevState.period,
                            remaining: 0,
                            cooldown: Number(resp.headers.get("x-ratelimit-cooldown-requests")!) ?? prevState.cooldown
                        }
                    })
                }
                return resp
            }
            setLimits((prevState) => {
                return {
                    limit: Number(resp.headers.get("x-ratelimit-limit-requests")!) ?? prevState.limit,
                    period: Number(resp.headers.get("x-ratelimit-period-requests")!) ?? prevState.period,
                    remaining: Number(resp.headers.get("x-ratelimit-remaining-requests")!) ?? prevState.remaining,
                    cooldown: Number(resp.headers.get("x-ratelimit-cooldown-requests")!) ?? prevState.cooldown,
                    lastUpdated: Date.now()
                }
            })
            return resp
        } catch (e) {
            reporter(`${e}`)
            throw e
        }
    }, [reporter, getHeaders, setLimits])

    const fetchCase = useCallback(async () => {
        try {
            updateSupportCase((prevState) => {
                const supportCase = prevState?.supportCase || emptyCase();
                return {
                    supportCase: supportCase,
                    isLoading: prevState.isLoading,
                    isSyncing: true
                }
            })
            const caseResponse = await fetchData("/api/cases/" + caseId, "GET", undefined)
            if (!caseResponse.ok) {
                updateSupportCase((prevState) => {
                    return {
                        supportCase: emptyCase(),
                        isLoading: false,
                        isSyncing: false
                    }
                })
                return
            }
            const caseInfo = await caseResponse.json()

            const messages = caseInfo.messages as BackendMessageResponse[];
            const filteredMessages = messages
                .map(m => {
                    return {
                        "text": m.text,
                        "messageType": m.party == "user" ? CaseMessageType.USER_MESSAGE : CaseMessageType.MESSAGE_RESPONSE,
                        "messageRole": toRole(m.role),
                        "requestedBy": m?.user_id,
                        "timeToRespond": m?.time_to_respond === undefined ? undefined : +m?.time_to_respond,
                        "creationTime": m?.creation_time,
                        "attachment_id": m?.attachment_id
                    } as CaseMessage
                })
                .filter(m => m.messageRole !== CaseMessageRole.SUMMARY && m.messageRole !== CaseMessageRole.ESCALATION)
            updateSupportCase((prevState) => {
                return {
                    supportCase: {
                        ...caseInfo,
                        "messages": filteredMessages
                    },
                    isLoading: prevState?.isLoading || false,
                    isSyncing: false
                }
            })
        } catch (e) {
            updateSupportCase((prevState) => {
                return {
                    supportCase: prevState?.supportCase || emptyCase(),
                    isLoading: prevState.isLoading,
                    isSyncing: false
                }
            })
        }
    }, [updateSupportCase, fetchData, caseId])

    const deleteSupportCase = useCallback(async () => {
        setCaseStateMap(prevState => {
            if (caseId === null || caseId === undefined) {
                return prevState
            }
            const res = new Map(prevState);
            res.delete(caseId)
            return res
        })
    }, [caseId, setCaseStateMap])


    useEffect(() => {
        if (!isLoggedIn() && caseStateMap.size > 0) {
            setCaseStateMap(() => new Map())
            setLastCaseIdLoaded(undefined)
        }
    }, [isLoggedIn, caseStateMap, setCaseStateMap]);

    useEffect(() => {
        if (!isLoggedIn()) {
            return
        }
        if (caseId === null || caseId === undefined) {
            return
        }
        if (caseId === lastCaseIdLoaded) {
            return
        }
        setLastCaseIdLoaded(caseId)
        if (caseStateMap.has(caseId) && caseStateMap.get(caseId)?.isLoading === true) {
            return
        }
        fetchCase()
    }, [caseId, lastCaseIdLoaded, fetchCase, caseStateMap, isLoggedIn])


    return useMemo(() => {
        const emptyCaseState = {supportCase: emptyCase(), isLoading: true, isSyncing: false}
        const caseState = caseId != undefined ? caseStateMap.get(caseId) : undefined
        return {
            supportCaseState: caseState,
            updateSupportCase: updateSupportCase,
            deleteSupportCase: deleteSupportCase,
            caseId: caseId,
            caseStateMapSize: caseStateMap.size,
            limits: limits,
            fetchData: fetchData,

        }
    }, [updateSupportCase, deleteSupportCase, caseId, caseStateMap, limits, fetchData])
}

