import {CaseHistory, collapsedInitially} from "./CaseHistory";
import {Discussion} from "./Discussion";
import {CaseMeta} from "./CaseMeta";
import React, {useCallback, useEffect, useState} from "react";
import {CaseMessage, CaseMessageRole, CaseMessageType} from "./Conversation";
import {useTranslation} from "react-i18next";
import {CaseInfo, CaseMetaInfo, EscalationResponse, IssueTracker} from "./model";
import {ErrorViewer, useErrorReporter} from "./ErrorViewer";
import {LoginButton} from "../components/LoginButton";
import {TitleBox} from "./TitleBox";
import {ActionButton} from "./ActionButton";
import {useAuth} from "../auth/AuthHook";
import {Link, useNavigate, useParams} from "react-router-dom";
import {DocViewer} from "./DocViewer";
import {SupportCaseState, useSupportCase} from "./CaseHook";


interface SupportFrameProps {
    show?: string
}

export function SupportFrame({show}: SupportFrameProps) {
    const {caseId} = useParams();
    const docPath = useParams()["*"];


    const [cases, setCases] = useState<CaseInfo[]>([])
    const {t} = useTranslation()
    const {isLoggedIn, getHeaders, getUserId} = useAuth()
    const reporter = useErrorReporter()
    const [isInitialized, setInitialized] = useState(false)
    const [releaseInfo, setReleaseInfo] = useState("")
    const [issueTrackerName, setIssueTrackerName] = useState("")
    const [initiationTimeStamp, setInitiationTimeStamp] = useState(0)
    const [caseHistoryCollapsed, setCaseHistoryCollapsed] = useState(collapsedInitially)
    const navigate = useNavigate()
    const {supportCaseState, updateSupportCase, deleteSupportCase, fetchData} = useSupportCase()

    const setWaitingForResponse = useCallback((waiting: boolean) => {
        updateSupportCase((prevState) => {
            return {
                ...prevState,
                isLoading: waiting
            }
        })
    }, [updateSupportCase])

    const doFetchHistory = useCallback(async () => {
        try {
            const response = await fetchData(`/api/cases/`, "GET")
            if (response.ok || response.status <= 299) {
                setCases(await response.json())
                setInitialized(true)
            }
        } catch (e) {
            // ignore
        }
    }, [fetchData])

    const fetchHistory = useCallback(async () => {
        if (isLoggedIn()) {
            await doFetchHistory();
        } else {
            setCases([])
        }
    }, [isLoggedIn, doFetchHistory])

    const fetchBuildInfo = useCallback(async () => {
        try {
            if (isLoggedIn()) {
                const resp = await fetch("/api/server/build_info/", {
                    headers: {
                        ...await getHeaders(),
                        "Content-Type": "application/json",
                    },
                })
                if (!resp.ok) {
                    reporter(`Error received while fetching build info: ${resp.status} - ${await resp.text()}`)
                    return
                }
                const id_resp = await resp.json();
                const rel = id_resp["release"]
                if (rel != undefined) {
                    setReleaseInfo(rel)
                }
            } else {
                setReleaseInfo("")
            }
        } catch (e) {
            reporter(`Cannot get build info: ${e}`);
        }
    }, [isLoggedIn, getHeaders, reporter])

    const fetchIssueTrackerName = useCallback(async () => {
        try {
            if (isLoggedIn()) {
                const resp = await fetch("/api/organisations/self/issue_tracker/", {
                    headers: {
                        ...await getHeaders(),
                        "Content-Type": "application/json",
                    },
                })
                if (!resp.ok) {
                    reporter(`Error received while fetching issue tracker name: ${resp.status} - ${await resp.text()}`)
                    return
                }
                const issueTracker_resp = await resp.json();
                const issueTrackerName = issueTracker_resp["issueTrackerName"]
                if (issueTrackerName != undefined) {
                    setIssueTrackerName(issueTrackerName)
                }
            }
        } catch (e) {
            reporter(`${e}`);
        }
    }, [isLoggedIn, getHeaders, reporter])


    useEffect(() => {
        fetchBuildInfo()
        fetchIssueTrackerName()
        fetchHistory()
    }, [fetchBuildInfo, fetchHistory, fetchIssueTrackerName])

    const updateOrCreate = useCallback(async (isNew: boolean, caseId: string | null, metaInfo: CaseMetaInfo) => {
        try {
            console.log(`Updating or creating case: ${JSON.stringify(metaInfo)}`)
            const resp = await fetch("/api/cases/" + (isNew ? "" : caseId), {
                method: "POST",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(metaInfo)
            })
            if (!resp.ok) {
                reporter(`Error received while updating case: ${resp.status} - ${await resp.text()}`)
                return null;
            }
            const resp_json = await resp.json();
            fetchHistory()
            return resp_json
        } catch (e) {
            reporter(`${e}`);
            return null;
        }
    }, [fetchHistory, getHeaders, reporter])

    const handleMetaInfoUpdate = useCallback(async (caseId: string | null, metaInfo: CaseMetaInfo) => {
        const meta = await updateOrCreate(caseId == null, caseId, metaInfo);
        if (meta !== null) {
            updateSupportCase((c) => {
                return {
                    ...c,
                    supportCase: {
                        ...c.supportCase!,
                        ...meta
                    }
                }
            })
            navigate(`/cases/${meta.case_id}`)
        }
    }, [updateSupportCase, updateOrCreate, navigate])

    const handleNewCaseInitiation = useCallback(() => {
        navigate("/")
        setInitiationTimeStamp(Date.now())
    }, [navigate])

    const handleCaseSelected = useCallback(async (caseId: string) => {
        navigate(`/cases/${caseId}`)
    }, [navigate])


    const addIssueTrackerDetails = useCallback((incoming_issue_tracker: IssueTracker) => {
        updateSupportCase((c) => {
            return {
                ...c,
                supportCase: {
                    ...c.supportCase!,
                    issue_tracker: incoming_issue_tracker
                },
            } as SupportCaseState
        })
    }, [updateSupportCase])


    const addNewMessage = useCallback((msg: CaseMessage) => {
        updateSupportCase((c) => {
            return {
                ...c,
                supportCase: {
                    ...c.supportCase!,
                    messages: [...c.supportCase?.messages ?? [], msg]
                }
            } as SupportCaseState
        })
    }, [updateSupportCase])


    const handleEscalation = useCallback(async () => {
        try {
            setWaitingForResponse(true)
            const response = await fetch(`/api/cases/${caseId}/escalate`, {
                method: "POST",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok || response.status > 299) {
                reporter("Escalation error: " + await response.text())
                return
            }
            const parsedResponse: EscalationResponse = await response.json();
            addNewMessage({
                text: parsedResponse.escalation_message.text,
                messageType: CaseMessageType.MESSAGE_RESPONSE,
                messageRole: CaseMessageRole.ESCALATION_RESPONSE,
                timeToRespond: parsedResponse.escalation_message.time_to_respond
            });
            addIssueTrackerDetails(parsedResponse.issue_tracker)
            updateSupportCase((c) => {
                return {
                    ...c,
                    supportCase: {
                        ...c.supportCase!,
                        status: "Escalated"
                    }
                }
            })
            await fetchHistory()
        } catch (e) {
            reporter(`${e}`);
        } finally {
            setWaitingForResponse(false)
        }
    }, [caseId, updateSupportCase, getHeaders, addNewMessage, reporter, addIssueTrackerDetails, fetchHistory, setWaitingForResponse])

    const handleClosure = useCallback(async () => {
        try {
            setWaitingForResponse(true)

            const response = await fetch(`/api/cases/${caseId}/close`, {
                method: "POST",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok || response.status > 299) {
                reporter("Error receiving response for closure")
                return
            }
            updateSupportCase((c) => {
                return {
                    ...c,
                    supportCase: {
                        ...c.supportCase!,
                        status: "Closed"
                    },
                }
            })
            await fetchHistory()
        } catch (e) {
            reporter(`${e}`);
        } finally {
            setWaitingForResponse(false)
        }
    }, [caseId, updateSupportCase, fetchHistory, getHeaders, setWaitingForResponse, reporter])

    const handleDelete = useCallback(async () => {
        try {
            setWaitingForResponse(true)

            const response = await fetch(`/api/cases/${caseId}`, {
                method: "DELETE",
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok || response.status > 299) {
                const errorMsg = await response.text()
                reporter(`Error receiving response for deletion: ${errorMsg}`)
                return
            }
            await deleteSupportCase()
            await fetchHistory()
            navigate("/")
            reporter(t("Case deleted permanently"))
        } catch (e) {
            reporter(`${e}`);
        } finally {
            setWaitingForResponse(false)
        }
    }, [caseId, fetchHistory, getHeaders, reporter, navigate, deleteSupportCase, setWaitingForResponse, t])

    const noOp = useCallback(() => {}, [])

    return <>
        <div id="main-support-frame" className={`flex flex-row justify-between grow overflow-y-auto w-full h-full`}>
            <div id="first-column"
                 className={`flex flex-col justify-start bg-black overflow-y-auto h-full ${caseHistoryCollapsed ? "min-w-[20%] 2xl:min-w-[10%]" : "min-w-[30%] 2xl:min-w-[25%]"}`}>
                <TitleBox lineHidden={true}>
                    <div className="text-l text-blue-enov text-center font-bold">
                        <Link to="/">
                            Nestor.ai
                        </Link>
                    </div>
                    <div className="text-l text-white text-center">
                        {t("Technical support assistant")}
                    </div>
                    <div className={`${releaseInfo == "" || !isLoggedIn() ? "hidden" : ""} text-xs`}>
                        {releaseInfo}
                    </div>
                </TitleBox>
                <CaseHistory
                    onItemSelected={handleCaseSelected}
                    onCollapseToggled={(collapsed) => {
                        setCaseHistoryCollapsed(collapsed)
                    }}
                    selectedCaseId={caseId ?? null}
                    initialized={isInitialized}
                    cases={cases}
                />
            </div>
            <div id="second-column"
                 className="flex flex-col justify-between h-full bg-gray-really overflow-auto overflow-y-auto grow">
                {isLoggedIn() && (
                    <TitleBox lineHidden={false}>
                        {caseId === undefined && (show === "cases" || show === undefined) ? (
                                <div className="text-2xl text-white text-center ">
                                    {t("There are no preceding messages to display")}
                                </div>
                            )
                            : (show === "cases") ? (
                                <div className="text-2xl text-white text-center">
                                    {supportCaseState?.supportCase?.title}
                                </div>

                            ) : (
                                <div className="text-2xl text-white text-center">
                                    <div>{t("Reference document")}</div>

                                    <div>{docPath?.split("/").pop()}</div>
                                </div>
                            )
                        }
                    </TitleBox>
                )}
                <div id="supportframe-discussion-container"
                     className="flex flex-col items-center w-full grow overflow-auto">
                    {
                        show === "cases" || show === undefined ?
                            <Discussion
                                key={caseId}
                            /> : <DocViewer
                                path={docPath}
                                doc_prefix="/docs"
                                internal_prefix="/internal"/>

                    }

                </div>
            </div>
            <div id="third-column" className="py-4 flex flex-col justify-between bg-black max-w-[25%] xl:max-w-[15%]">
                <div className={"flex flex-col justify-start"}>
                    <div className="px-6 py-2">
                        <LoginButton
                            onAuthenticationError={reporter}
                            onAuthenticationSuccess={noOp}
                        />
                    </div>
                    <div className="px-6 py-2">
                        <ActionButton
                            className="text-left flex-row justify-start"
                            onClick={handleNewCaseInitiation}>
                            {t("Create new case ...")}
                        </ActionButton>
                    </div>

                </div>

                <div className="flex flex-col justify-start pt-12 h-fit overflow-y-auto">
                    <CaseMeta
                        onSubmitChanges={handleMetaInfoUpdate}
                        titleHidden={true}
                        initTimeStamp={initiationTimeStamp}
                        issueTrackerName={issueTrackerName}
                    />
                    <ErrorViewer/>
                    <div className="px-6 py-2">
                        {issueTrackerName === "notracker" || supportCaseState?.supportCase?.issue_tracker?.issue_key == null ?
                            (<ActionButton
                                className={`text-left flex-row justify-start disabled:bg-inherit`}
                                disabled={
                                    (supportCaseState?.supportCase?.messages?.length ?? 0) == 0 ||
                                    supportCaseState?.supportCase?.status.toLowerCase() === "closed" ||
                                    supportCaseState?.supportCase?.status === "Escalated" ||
                                    (issueTrackerName !== "notracker" &&
                                        (supportCaseState?.supportCase?.issue_tracker == undefined ||
                                            supportCaseState?.supportCase?.issue_tracker.project_key == undefined || supportCaseState?.supportCase?.issue_tracker.project_key === ""))
                                }
                                onClick={handleEscalation}>
                                {t("Escalate")}
                            </ActionButton>)
                            :
                            (<ActionButton
                                className={`text-left flex-row justify-start disabled:bg-inherit`}
                                hidden={supportCaseState?.supportCase?.status !== "Escalated"}
                                onClick={() => window.open(supportCaseState?.supportCase?.issue_tracker?.issue_url, "_blank")}>
                                {t("Open in " + issueTrackerName)}
                            </ActionButton>)
                        }
                    </div>
                    <div className="px-6 py-2">
                        <ActionButton
                            className="text-left flex-row justify-start"
                            onClick={handleClosure}
                            disabled={supportCaseState?.supportCase === undefined || supportCaseState?.supportCase?.status.toLowerCase() === "closed"}>
                            {t("Close case")}
                        </ActionButton>
                    </div>
                    <div className="px-6 py-2">
                        <ActionButton
                            className="text-left flex-row justify-start"
                            onClick={handleDelete}
                            disabled={supportCaseState?.supportCase == undefined}>
                            {t("Delete case")}
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    </>
}